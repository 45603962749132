<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <schedule-table-card/>
      </v-col>
    </v-row>
  </v-container> 
</template>

<style lang="scss" scoped>
  .v-data-table {
    white-space : nowrap;
  }
  .v-data-table--fixed-header >>> th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 9999;
    background: white;
  }
  .container{
     max-width: 98%;
    //  max-height: 98%;
     padding:-1px;
  }
</style>

<script>
import scheduleTableCard from '@/components/organisms/ScheduleTableCard.vue'
export default {
  name: 'ITMScheduleTablePage',
  data () {
    return {
      card: "csv"
    }
  },
  components: {
    scheduleTableCard,
  },
  computed: {

  },
  methods: {
  }
}
</script>