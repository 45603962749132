<template>

  <v-card>
    <v-app-bar color="primary" dark>
      <v-toolbar-title><v-icon>mdi-home</v-icon>物件選択</v-toolbar-title>
    </v-app-bar>
    <v-container>
      <v-row>
        <v-col md="3" offset-md="6">
          <v-select
          v-model="selectedColumn"
          :items="searchColumns" 
          label="検索列の選択"
          item-text="text"
          item-value="value"
          return-object
          @change="getColumnName">
          </v-select>
        </v-col>
        <v-col md="3">
          <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="検索"
          single-line
          hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="buildingDataList"
            :items-per-page="5"
            class="elevation-1"
            :search="search"
            :custom-filter="customFilter"
          >
            <template v-slot:item.select_building="{ item }">
              <v-btn color="success" @click="showModal(item)">選択</v-btn>                     
            </template>
          </v-data-table>
        </v-col>
      </v-row>


      <!-- 物件選択ボタンを押したときに出るmodal -->
      <v-row justify="center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              この物件を選択しますか？
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="headline">
              {{ selectedBuildingItem.building_name }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="setBuilding(selectedBuildingItem)">OK</v-btn>
              <v-btn color="primary" text @click="dialog=false">キャンセル</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-card>

</template>

<style lang="scss" scoped>
  .v-data-table {
    white-space : nowrap;
  }
  .v-data-table--fixed-header >>> th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 9999;
    background: white;
  }
  .container{
     max-width: 98%;
    //  max-height: 98%;
     padding:-1px;
  }
</style>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'SelectBuildingTableCard',
  data () {
    return {
      headers:  [
        { "text": "物件ID", "align": "start", "sortable": false, "value": "building_ID", class: 'title' },
        { "text": "物件名", "value": "building_name", class: 'title' },
        { "text": "契約者名", "value": "customer_name", class: 'title' },
        { "text": "担当SS", "value": "SS_CD", class: 'title' },
        { "text": "お客様ID", "value": "customer_ID", class: 'title' },
        { "text": "物件選択", "value": "select_building", class: 'title'}
        
      ],
      searchColumns:[
        { "text": "物件ID", "value": "building_ID" },
        { "text": "物件名", "value": "building_name" },
        { "text": "契約者名", "value": "customer_name" },
        { "text": "担当SS", "value": "SS_CD" },
        { "text": "お客様ID", "value": "customer_ID" }
      ],
      selectedColumn: { text: '物件名', value: 'building_name' },
      search: '',
      dialog: false,
      selectedBuildingItem: { building_name: "" }
    }
  },
  computed: {
    ...mapState(
      ['buildingDataList']
    )
  },
  methods: {
    ...mapMutations(
      ['setBuildingItem']
    ),
    ...mapActions(
      ['fetchBuildingTable']
    ),
    getColumnName(column) {
        console.log(column)
        this.selectedColumn = column
    },
    customFilter(value, search, item) {
      // value:表のそれぞれの値, search:検索文字列, item:表のそれぞれの行object
      const column = this.selectedColumn.value
      // 説明のコメントが必要
      return value != null && item[column].toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    setBuilding(item){
      this.setBuildingItem(item)
      this.dialog = false
      console.log(this.dialog)
      this.$router.push('/uploadPage')
    },
    showModal: function(item) {
      console.log(item)
        this.selectedBuildingItem = item
        this.dialog = true
    }
  },

  created: function () {
    if (this.buildingDataList.length == 0){
      this.fetchBuildingTable() // action
    }
  }
}
</script>