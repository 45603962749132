<template>
  <v-card>
    <v-app-bar color="primary" dark>
    <v-toolbar-title><v-icon>mdi-table-edit</v-icon>期間データ編集</v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-btn color="primary" outlined @click="compareSeasonSchedule()">
        確認
      </v-btn>
      <v-row>
        <v-col md="3">
          <v-select 
            v-model="selectedSeason"
            :items="seasonList" 
            label="期間の選択"
            item-text="text"
            item-value="value"
            outlined 
            return-object
          >
          </v-select>
        </v-col>
        <v-col md="3">
          <v-select 
            v-model="selectedPoint"
            :items="pointList" 
            label="管理点の選択"
            item-text="text"
            item-value="value"
            outlined 
            return-object
          >
          </v-select>
        </v-col>
        <v-col md="3">
          <v-select
            v-model="selectedSchedule"
            :items="scheduleList" 
            label="スケジュールの選択"
            item-text="text"
            item-value="value"
            outlined 
            return-object
          >
          </v-select>
        </v-col>
      </v-row>

        <!-- 確認ボタンを押したときのmodal -->
        <v-row justify="center">
          <v-dialog v-model="confirmDialog" width="600">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                編集履歴の確認
              </v-card-title>
              <v-card-text class="headline">
                <v-row justify="center">
                  <v-col align="left">
                    期間：{{ editSeasonLog["有効期間"] }}
                  </v-col>
                  <v-col align="left">
                    管理点：{{ editSeasonLog["管理点名"] }}
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols="4" offset-md="4" align="center">
                      変更前
                    </v-col>
                    <v-col cols="4" align="center">
                      変更後
                    </v-col>
                </v-row>
                <v-divider></v-divider>

                  <v-row>
                    <v-col cols="4" align="right">
                      スケジュール名
                    </v-col>
                    <v-col cols="4" align="center">
                      {{ editSeasonLog["master"] }}
                    </v-col>
                    <v-col cols="4" align="center">
                      {{ editSeasonLog["local"] }}
                    </v-col>
                  </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click="saveSeason()">保存</v-btn>
                <v-btn color="primary" large @click="confirmDialog=false">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </v-container>
  </v-card>
</template>
<style scoped>
  .v-data-table {
    white-space : nowrap;
  }
  .container{
     max-width: 98%;
     padding:-1px;
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'EditSeasonDataCard',
  data () {
      return {
        selectedSeason: "",
        selectedPoint: "",
        selectedSchedule: "",
        confirmDialog: false
      }
  },
  components: {
  },
  computed: {
    ...mapState([
        "seasonList", "buildingPointItems", "buildingScheduleItems", "editSeasonLog"
    ]),
    pointList () {
      const pointList = []
      this.buildingPointItems.forEach(elm => {
        pointList.push(elm["管理点名"])
      })
      return pointList
    },
    scheduleList () {
      const scheduleList = []
      this.buildingScheduleItems.forEach(elm => {
        scheduleList.push(elm["scheduleName"])
      })
      return scheduleList
    },
    editedSeasonLog() {
      const editedSeasonJson = {
        season: this.selectedSeason,
        point: this.selectedPoint,
        schedule: this.selectedSchedule
      }
      return editedSeasonJson
    }
  },
  methods: {
    ...mapActions([
      "compareEditedSeasonData", "saveEditedSeasonData"
    ]),
    compareSeasonSchedule(){
      console.log("!")
      const editedSeasonJson = {
        season: this.selectedSeason,
        point: this.selectedPoint,
        schedule: this.selectedSchedule
      }
      this.compareEditedSeasonData({ seasonJson: editedSeasonJson })
      this.confirmDialog = true
    },
    saveSeason() {

      this.saveEditedSeasonData({ editLog: this.editedSeasonLog})
      this.confirmDialog = false
    },

  },
}
</script>