/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-northeast-1",
  "aws_cognito_identity_pool_id": "ap-northeast-1:e6228f3c-6170-40bd-ab56-3459b955fb13",
  "aws_cognito_region": "ap-northeast-1",
  "aws_user_pools_id": "ap-northeast-1_5QtXsQqpy",
  "aws_user_pools_web_client_id": "7lifrn6jsjibg9a7n8jlhb9808",
  "oauth": {},
  "aws_user_files_s3_bucket": "esolitmschedule131125-dev",
  "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;