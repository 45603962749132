// import Vue from 'vue'
import { getDefaultState } from './index'
export default {
    incrementTasksOnServer (state) {
        state.ongoingTasksOnServer = state.ongoingTasksOnServer + 1
         },
    decrementTasksOnServer (state) {
        state.ongoingTasksOnServer = state.ongoingTasksOnServer - 1
    },
    showErrorMessage(state, payload) {
        state.errorMessage = payload
        state.snackbarIsActive = true
    },
    setUploadCsv (state, payload){
        console.log(state.uploadedCsvList)
        if(state.uploadedCsvList.indexOf(payload.uploadCsv) == -1){
            state.uploadedCsvList.push(payload.uploadCsv)
        }
        console.log(state.uploadedCsvList)
    },
    setUploadedCsvFromS3 (state, payload){
        state.uploadedCsvList = payload
    },
    setSelfComparedScheduleItems (state, payload){
        state.selfComparedCsvScheduleItems = payload
    },
    setComparedCsvScheduleItems (state, payload){
        state.comparedCsvScheduleItems = payload
    },
    setSeason (state, payload){
        state.season = payload
    },
    setTableHeader (state, payload){
        state.scheduleTableHeaders = payload
    },
    setfixedPeriodScheduleData (state, payload){
        state.fixedPeriodScheduleData = payload
    },
    setDateScheduleData (state, payload){
        state.dateScheduleData = payload
    },
    setScheduleSeasonList (state, payload){
        state.seasonList = payload
    },
    setBuildingDataList (state, payload){
        state.buildingDataList = payload
        console.log(state.buildingDataList)
    },
    setAnyPeriodScheduleData (state, payload){
        state.anyPeriodScheduleDataList = payload
    },
    setBuildingItem (state, payload){
        state.buildingItem = payload
    },
    setUserName ( state, payload ){
        state.userName = payload
    },
    setBuildingPointItems ( state, payload ){
        state.buildingPointItems = payload
    },
    setBuildingScheduleHeaders( state, payload ){
        state.buildingScheduleHeaders = payload
    },
    setBuildingScheduleItems ( state, payload ){
        state.buildingScheduleItems = payload
    },
    setTimeHeaders ( state, payload ){
        state.timeHeaders = payload
    },
    setEditScheduleLogList( state, payload ){
        state.editScheduleLogList = payload
    },
    setEditPointLogList( state, payload ){
        state.editPointLogList = payload
    },
    setEditSeasonLog( state, payload ){
        state.editSeasonLog = payload
    },
    resetState (state) {
        Object.assign(state, getDefaultState())
    }    

}