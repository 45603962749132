import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home from '../views/Home.vue'
import loginPage from '@/components/pages/LoginPage'
import selectBuildingPage from '@/components/pages/SelectBuildingPage.vue'
import uploadPage from '@/components/pages/UploadPage.vue'
import scheduleTablePage from '@/components/pages/ScheduleTablePage.vue'
import buildingInfoPage from '@/components/pages/BuildingInfoPage.vue'
import editDataPage from '@/components/pages/EditDataPage.vue'

import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'


Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loginPage',
    component: loginPage
  },
  {
    path: "/selectBuilding",
    name: "selectBuildingPage",
    component: selectBuildingPage
  },
  {
    path: "/uploadPage",
    name: "uploadPage",
    component: uploadPage
  },
  {
    path: "/buildingInfoPage",
    name: "buildingInfoPage",
    component: buildingInfoPage
  },

  {
    path: "/scheduleTablePage",
    name: "scheduleTablePage",
    component: scheduleTablePage
  },
  {
    path: "/editDataPage",
    name: "editDataPage",
    component: editDataPage
  },

]

// let user

// ユーザー管理
// getUser().then((user) => {
//   if (user) {
//     router.push({ path: '/selectbuilding' })
//   }
// })
// console.log('router/index.js')

// function getUser () {
//   return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
//     if (data && data.signInUserSession) {
//       store.commit('setUserName', data.username)
//       // this.setUserName(this.userName)
//       return data
//     }
//   }).catch(() => {
//     return null
//   })
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// リダイレクト設定
// router.beforeResolve(async (to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     let user
//     user = await getUser()
//     if (!user) {
//       console.log("リダイレクト成功")
//       return next({
//         path: '/'
//       })
//     }
//     return next()
//   }
//   return next()
// })

export default router
