Vue.config.devtools = true
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './components/store'
import axios from 'axios'
import router from './router'
import awsmobile from './aws-exports'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue'

Amplify.configure(awsmobile);

axios.interceptors.request.use(async request => {
  store.commit('incrementTasksOnServer')
  const currSession = await Vue.prototype.$Amplify.Auth.currentSession()
  request.headers.Authorization = currSession.getIdToken().getJwtToken()
  return request
})
axios.interceptors.response.use(
  response => {
    store.commit('decrementTasksOnServer')
    return response
  },
  error => {
    store.commit('decrementTasksOnServer')
    store.commit('showErrorMessage', error)
    return Promise.reject(error)
  }
)

Vue.use(AmplifyPlugin, AmplifyModules);

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
