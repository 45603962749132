<template>
    <v-card>
      <v-app-bar color="primary" dark>
      <v-toolbar-title><v-icon>mdi-table-edit</v-icon>管理点データ編集</v-toolbar-title>
      </v-app-bar>
      <v-container fluid>
        <v-btn color="primary" outlined @click="comparePoint()">
          確認
        </v-btn>
        <v-row justify="center">
          <v-col>
            <v-data-table
              :headers="headers"
              :items="pointItems"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item="{ item, index}">
                <tr>
                  <td 
                    v-for="(header, i) in headers" :key="i"
                    align="center"
                    style= "cursor:pointer"
                    @click="showCellEditModal(header, index)"
                  >
                    {{ item[header.value] }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- セルをクリックしたときに出るmodal -->
        <v-row justify="center">
          <v-dialog v-model="cellDialog" width="450">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                {{ pointItems[selectedIndex]["管理点名"] }}の{{ selectedHeader.text }}を編集
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="headline">
                <v-row>
                  <v-col cols="4" offset-md="4">
                    <v-text-field type="str" v-model="edittingValue"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click="editCellValue()">OK</v-btn>
                <v-btn color="primary" large @click="cellDialog=false">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <!-- 確認ボタンを押したときのmodal -->
        <v-row justify="center">
          <v-dialog v-model="confirmDialog" width="600">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                編集履歴の確認
              </v-card-title>
              <v-card-text class="headline">
                <v-row>
                    <v-col cols="4" offset-md="4" align="center">
                      変更前
                    </v-col>
                    <v-col cols="4" align="center">
                      変更後
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(pointName, i) in editedPointList" :key="i" > 
                  <v-row>
                    <v-col align="left">
                      {{ pointName }}
                    </v-col>
                  </v-row>
                    <v-row v-for="(logItem, i) in filterLogList(pointName)" :key="i">
                      <v-col cols="4" align="right">
                        {{ logItem.header }}
                      </v-col>
                      <v-col cols="4" align="center">
                        {{ logItem["master"] }}
                      </v-col>
                      <v-col cols="4" align="center">
                        {{ logItem["local"] }}
                      </v-col>
                    </v-row>
                  </div>
                <!-- </div> -->
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click="savePoint()">保存</v-btn>
                <v-btn color="primary" large @click="confirmDialog=false">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

      </v-container>
    </v-card>
</template>
<style scoped>
  .v-data-table {
    white-space : nowrap;
  }
  .container{
     max-width: 98%;
     padding:-1px;
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'EditPointDataCard',
  data () {
      return {
        pointItems: [],
        headers:  [
            { "text": "管理点名", "align": "center", "sortable": false, "value": "管理点名" },
            { "text": "冷房下限", "align": "center", "value": "冷房下限" },
            { "text": "暖房上限", "align": "center", "value": "暖房上限" },
            { "text": "ポート", "align": "center", "value": "ポート" },
            { "text": "集中アドレス", "align": "center", "value": "集中アドレス" },
            { "text": "ANアドレス", "align": "center", "value": "ANアドレス" },
            { "text": "LC番号", "align": "center", "value": "LC-No." },
        ],
        selectedIndex: 0,
        selectedHeader: '',
        edittingValue: '',
        cellDialog: false,
        confirmDialog: false
      }
  },
  components: {
  },
  computed: {
    ...mapState([
      "buildingPointItems", "editPointLogList"
    ]),
    editedPointList() {
      const pointSet = new Set(this.editPointLogList.map(elm => elm["管理点名"])) 
      return Array.from(pointSet)
    }
  },
  methods: {
    ...mapActions([
      "compareEditedPointData", "saveEditedPointData"
    ]),
    comparePoint() {
      // this.saveDialog = true
      console.log("comparePoint")
      this.compareEditedPointData({ pointJson: this.pointItems })
      this.confirmDialog = true
    },
    savePoint() {
      this.saveEditedPointData({ editLog: this.editPointLogList})
      this.confirmDialog = false
    },
    showCellEditModal(header, index) {
      console.log(header, index)
      console.log("showCellEditModal")
      this.selectedHeader = header
      this.selectedIndex = index
      this.edittingValue = this.pointItems[this.selectedIndex][this.selectedHeader.value]
      this.cellDialog = true
    },
    editCellValue() {
      this.pointItems[this.selectedIndex][this.selectedHeader.value] = this.edittingValue
      this.cellDialog = false
    },
    filterLogList(point) {
      return this.editPointLogList.filter(elm => elm["管理点名"] == point)
    }
  },
  mounted: function () {
    console.log(this.buildingPointItems)
    this.pointItems = [...this.buildingPointItems]
  }
}
</script>