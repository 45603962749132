<template>
    <v-dialog
      v-model="showLoadingModal"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
            <br>
            <span style="font-size:x-large">データ読み込み中...</span>
            <br>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
  name: 'loadingModal',
  props: {
    showLoadingModal: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
    }
  },
  computed: {
  }
      }
</script>
