<template>
  <v-container fluid>
    <!-- 管理点情報カードのコンポーネント -->
    <v-card>
      <v-app-bar color="primary" dark>
        <v-toolbar-title><v-icon>mdi-information</v-icon>管理点情報</v-toolbar-title>
      </v-app-bar>
      <v-container fluid>
        <v-row justify="center">
          <v-col>
            <v-data-table
              :headers="headers"
              :items="buildingPointItems"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td align="center">{{ item['管理点名'] }}</td>
                  <td align="center">{{ item["冷房下限"] }}</td>
                  <td align="center">{{ item["暖房上限"] }}</td>
                  <td align="center">{{ item["ポート"] }}</td>
                  <td align="center">{{ item["集中アドレス"] }}</td>
                  <td align="center">{{ item["ANアドレス"] }}</td>
                  <td align="center">{{ item["LC-No."] }}</td>
                  <td>
                    <v-container>
                      <v-row>
                        <v-col v-for="(scheduleName, index) in getscheduleNameList(item)" :key="index">               
                          <v-chip color="success" dark>{{ scheduleName }}</v-chip>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- <v-chip color="success" dark v-on="on">{{ item["scheduleName"] }}</v-chip> -->
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<style scoped>
  .v-data-table {
    white-space : nowrap;
  }
  .container{
     max-width: 98%;
     padding:-1px;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'BuildingInfoCard',
  data () {
    return {
      headers:  [
        { "text": "管理点名", "align": "center", "sortable": false, "value": "管理点名" },
        { "text": "冷房下限", "align": "center", "value": "冷房下限" },
        { "text": "暖房上限", "align": "center", "value": "暖房上限" },
        { "text": "ポート", "align": "center", "value": "ポート" },
        { "text": "集中アドレス", "align": "center", "value": "集中アドレス" },
        { "text": "ANアドレス", "align": "center", "value": "ANアドレス" },
        { "text": "LC番号", "align": "center", "value": "LC番号" },
        { "text": "スケジュール", "align": "center", "value": "スケジュール名" }
      ]
    }
  },
  computed: {
    ...mapState([
      "buildingPointItems"
    ])
  },
  methods: {
    ...mapActions([
      'fetchBuildingPointItems'
    ]),
    getscheduleNameList (item){
      const scheduleNamelist = item["スケジュール名リスト"]
      console.log(typeof scheduleNamelist)

      return scheduleNamelist
    }
  },
  created: function () {
    this.fetchBuildingPointItems()
  }
}
</script>
