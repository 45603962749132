import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)
export const getDefaultState = () => {
    return {
        ongoingTasksOnServer: 0,


        uploadedCsvList: [],
        selfComparedCsvScheduleItems: [],
        comparedCsvScheduleItems: [],

        // ITMScheduleTableCard.vue
        season: '',
        seasonList: [],
        scheduleTableHeaders: [],
        fixedPeriodScheduleData: {},
        dateScheduleData: [],
        anyPeriodScheduleDataList: [],

        buildingDataList: [],
        buildingItem: { building_name: "" },
        userName: "",
        buildingPointItems: [],
        buildingScheduleHeaders: [],
        buildingScheduleItems: [],
        timeHeaders: [],
        editScheduleLogList: [],
        editPointLogList: [],
        editSeasonLog: {}
    }
}

// initial state
const state = getDefaultState()

export default new Vuex.Store({
    state, // 定義したstateを`state`オプションに指定
    getters,
    actions,
    mutations,
    strict: process.env.NODE_ENV !== 'production'
})