<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <select-building-table-card/>
      </v-col>
    </v-row>
  </v-container> 
</template>


<script>
import selectBuildingTableCard from '@/components/organisms/SelectBuildingTableCard.vue'
export default {
  name: 'selectBuildingPage',
  data () {
      return {

      }
  },
  components: {
    selectBuildingTableCard,
  },
  computed: {

  },
  methods: {
  }
}
</script>