<template>
    <b-navbar type="primary" variant="light" :sticky="isSticky" :fixed="fixed">
        <b-navbar-brand href="https://top.smart-e-ops.com">
        TOP
            </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item-dropdown text="分析ツール" right>
          <b-dropdown-item href="http://smart-e-ops.com/login_page">詳細分析</b-dropdown-item>
          <b-dropdown-item href="https://front-simple.smart-e-ops.com/">簡易分析</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="管理ツール" right>
          <b-dropdown-item href="#">契約物件管理</b-dropdown-item>
          <b-dropdown-item href="https://front-itm-schedule.smart-e-ops.com/">ITM設定可視化</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item href="http://10.30.7.30:55001/esol">取得ツール</b-nav-item>

        <!-- Navbar dropdowns -->

        <b-nav-item-dropdown text="問い合わせ" right>
          <b-dropdown-item href="#">Account</b-dropdown-item>
          <b-dropdown-item href="#">Settings</b-dropdown-item>
        </b-nav-item-dropdown>
        <!-- <b-nav-item>
          <amplify-sign-out/>
        </b-nav-item> -->
      </b-navbar-nav>
    </b-navbar>
</template>

<script>
export default {
    data() {
        return {
            mainProps: {
                center: true,
                width: 100
            },
            isSticky: true,
            fixed: "top"
        }
    }
}
</script>