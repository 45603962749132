<template>
  <v-app>
    <Navbar />
    
    <Sidebar v-if="signedIn"/>
    <loading-dialog :showLoadingModal="isLoading"></loading-dialog>
    
    <v-main>

      <!-- 認証済みの時はメインの画面を表示 -->
      <div v-if="signedIn">
        <div align="right">
          <amplify-sign-out/>
        </div>
        <router-view/>
      </div>
      <!-- 認証していない時はログイン画面を表示 -->
      <div v-else style="text-align:center;">
        <amplify-authenticator 
          :authConfig="{ signInConfig: { isSignUpDisplayed: false } }"
        >
        </amplify-authenticator>
      </div>

    </v-main>
  </v-app>
</template>

<script>

// import Itm from './components/ITM';
import loadingDialog from './components/molecules/loadingDialog.vue'
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { mapMutations } from 'vuex'
export default {
  name: 'App',

  components: {
    // Itm,
    loadingDialog,
    Navbar,
    Sidebar
  },

  data(){
    return {
      signedIn: false,
      userName: ''
    }
  },
  computed: {
    isLoading(){
      return this.$store.state.ongoingTasksOnServer > 0
    }
  },
  methods: {
    ...mapMutations([
      'setUserName',
      'resetState'
    ]),
  },
  async beforeCreate() {
    // Auth.currentAuthenticatedUser()でユーザ情報を取得する。
    // 取得できなければ認証ステータスをfalseに設定する
    try {
      let cognitoUser = await Auth.currentAuthenticatedUser()
      this.signedIn = true
      this.userName = cognitoUser.username

    } catch (err) {
      this.signedIn = false
    }
    // 認証ステータスが変わった時に呼び出されるイベントを登録
    AmplifyEventBus.$on('authState', async  info => {
      if (info === 'signedIn') {
        let cognitoUser = await Auth.currentAuthenticatedUser()
        this.signedIn = true
        this.userName = cognitoUser.username
        this.setUserName(this.userName)
        console.log(this.userName)
        this.$router.push("/selectbuilding")
      } else {
        if(this.$route.path != "/"){ 
          console.log("path", this.$route)
          this.$router.push("/")
        }
        this.signedIn = false
        this.resetState()
      }
    })
  }
}

</script>