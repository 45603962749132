<template>
    <div class="login">
        <!--ログインコンポーネント-->
        <amplify-authenticator
        :authConfig="{ signInConfig: { isSignUpDisplayed: false } }">
        </amplify-authenticator>

    </div>
</template>

<script>
export default {
  name: 'Login'
}
</script>