<template>
  <v-data-table
  :headers="headers"
  :items="items"
  :items-per-page="5"
  class="elevation-1"
  >
  <template v-slot:item="{ item }">
    <tr>
      <td align="center">{{ item['scheduleName'] }}</td>
      <td align="center">{{ item['header'] }}</td>
      <td 
        :bgcolor="getColor(item, 'local')"
        align="center"
        style="border:1px grey solid"
        height="4rem"
      >
        {{ item['local']["temperature"] }}
      </td>
      <td 
        :bgcolor="getColor(item, 'master')"
        align="center"
        style="border:1px grey solid"
        height="4rem"
      >
        {{ item['master']["temperature"] }}
      </td>
    </tr>
  </template>
    </v-data-table>
</template>

<script>
// import { mapState, mapActions } from 'vuex'
export default {
  name: 'comparedCsvScheduleTable',
  props:{
    items: { type: Array }, //{ text: '詳細名称', value: 'detailName' }
  },
  data () {
    return {
      headers:  [
        { "text": "スケジュール名", "align": "center", "sortable": false, "value": "scheduleName" },
        { "text": "時刻", "align": "center", "sortable": false, "value": "header" },
        { "text": "csv", "align": "center", "sortable": false, "value": "local", "width":"4rem" },
        { "text": "excel", "align": "center", "sortable": false, "value": "master", "width":"4rem" }
      ]
    }
  },
  methods: {
    getColor (value, col) {
      if(value[col]){
        if(value[col]["operation"] == "停止"){
          return '#FE66FF'
        }
        else if(value[col]["operation"] == "運転"){
          return '#68FE32'
        }
        else if (value[col]["temperature"])
          return '#FFFE08'
      }
    },
  }
}
</script>