<template>
    
  <v-card>
    <v-app-bar color="primary" dark>
      <v-toolbar-title><v-icon>mdi-file-upload-outline</v-icon>csvスケジュールの照合</v-toolbar-title>
    </v-app-bar>
      <v-container fluid>
        <v-row justify="left">
          <v-col cols="6">
              <p>csvデータ自身の照合結果</p>
              <self-compared-csv-schedule-table :items="selfComparedCsvScheduleItems">
              </self-compared-csv-schedule-table>
          </v-col>
        </v-row>
        <v-row justify="left">
          <v-col cols="6">
              <p>csvデータとエクセルデータの照合結果</p>
              <compared-csv-schedule-table :items="comparedCsvScheduleItems">
              </compared-csv-schedule-table>
          </v-col>
        </v-row>
      </v-container>
  </v-card>
    
</template>

<script>
import selfComparedCsvScheduleTable from '@/components/molecules/SelfComparedCsvScheduleTable.vue'
import comparedCsvScheduleTable from '@/components/molecules/ComparedCsvScheduleTable.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'compareCsvScheduleCard',
  components: {
    selfComparedCsvScheduleTable,
    comparedCsvScheduleTable
  },
  computed: {
    ...mapState([
      'selfComparedCsvScheduleItems', 'comparedCsvScheduleItems'
    ]),
    // comparedCsvScheduleItems() {
    //   return [
    //     {"schName": "冷房A", 
    //     "time": "00:00", 
    //     "csv": {"operation": "運転", "temperature": "+1"}, 
    //     "excel": {"operation": "温度シフト", "temperature": "+2"}
    //     }]
    // }
  },
  methods: {
    ...mapActions([
      'fetchSelfComparedSchedule'
    ]),
  }

}
</script>