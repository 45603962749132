<template>
    <v-card>
      <v-app-bar color="primary" dark>
        <v-toolbar-title><v-icon>mdi-table-edit</v-icon>スケジュールデータ編集</v-toolbar-title>
      </v-app-bar>
      <v-container fluid>
        <v-row>
          <v-col md="3">
            <v-btn color="primary" outlined @click="compareSchedule()">
              確認
            </v-btn>
            <v-btn color="primary" outlined @click="makeSchedule()">
              追加
            </v-btn>
          </v-col>
          <!-- <v-col md="3" offset-md="3">
            <v-select
              v-model="selectedSchedule"
              :items="scheduleList" 
              label="スケジュールの選択"
              item-text="text"
              item-value="value"
              outlined 
              return-object
            >
            </v-select>
          </v-col>
          <v-col>
            <v-btn color="primary" outlined @click="deleteSchedule()">
              削除
            </v-btn>
          </v-col> -->
        </v-row>
        <v-spacer/>
        <v-row justify="center">
          <v-col>
            <v-data-table
              :headers="scheduleHeaders"
              :items="scheduleItems"
              :items-per-page="10"
              class="elevation-1"
              fixed-header
            >
            <template v-slot:item="{ item, index }">
              <tr>
                <td align="center">
                  <v-chip 
                    color="success"
                    dark
                    @click="showSchNameEditModal('scheduleName', index)"
                  >
                    {{ item['scheduleName'] }}
                  </v-chip>
                </td>
                <td align="center" >
                  <v-menu open-on-hover :offset-x="true" >
                    <template v-slot:activator="{ on }">
                      <div @click="showNoteEditModal('note', index)" style="cursor:pointer;">
                        <v-icon v-if="item.note != ''" v-on="on">mdi-information-outline</v-icon>
                        <v-icon v-else>mdi-pencil</v-icon>
                        <!-- mdi-information-outline -->
                      </div>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title>{{ item.note }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td 
                  v-for="(time, i) in timeHeaders" :key="i"
                  :bgcolor="getColor(item, time)"
                  align="center"
                  class="cellStyle"
                  height="3rem"
                  @click="showCellEditModal(time, index)"
                >
                  {{ item[time]["temperature"] }}
                  <!-- {{ displayTempValue(item, time) }} -->
                </td>
              </tr>
            </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- スケジュール名の編集modal -->
        <v-row justify="center">
          <v-dialog v-model="schDialog" width="450">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                {{ scheduleItems[selectedIndex].scheduleName }}のスケジュール名を編集
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="4" offset-md="4">
                    <v-text-field v-model="edittingScheduleName" style="font-size: 1.7em"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click="editScheduleName()">OK</v-btn>
                <v-btn color="primary" large @click="schDialog=false">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <!-- 特記事項の編集modal -->
        <v-row justify="center">
          <v-dialog v-model="noteDialog" width="450">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                {{ scheduleItems[selectedIndex].scheduleName }}の特記事項を編集
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-textarea
                  label="備考"
                  v-model="edittingNote"
                >
                </v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click="editNote()">OK</v-btn>
                <v-btn color="primary" large @click="noteDialog=false">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <!-- セルをクリックしたときに出るmodal -->
        <v-row justify="center">
          <v-dialog v-model="cellDialog" width="450">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                {{ scheduleItems[selectedIndex].scheduleName }}の{{ selectedHeader }}を編集
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="headline">
                <v-row>
                  <v-col cols="4" offset-md="4">
                    <v-select :items="actionList" v-model="edittingAction" style="font-size: 0.5em"></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="edittingAction=='設定温度'">
                  <v-col cols="4" offset-md="4">
                    <v-text-field type="number" min=16 max=30 v-model.number="presetTemp"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="edittingAction=='温度シフト'">
                  <v-col cols="4" offset-md="4">
                    <v-select :items="shiftTempList" v-model="shiftTemp" style="font-size: 0.75em"></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click="editCellSchedule()">OK</v-btn>
                <v-btn color="primary" large @click="cellDialog=false">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <!-- 確認ボタンを押したときのmodal -->
        <v-row justify="center">
          <v-dialog v-model="saveDialog" width="600">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                編集履歴の確認
              </v-card-title>
              <v-card-text class="headline">
                <v-row>
                    <v-col cols="4" offset-md="4" align="center">
                      変更前
                    </v-col>
                    <v-col cols="4" align="center">
                      変更後
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(scheduleName, i) in editedScheduleList" :key="i" > 
                  <v-row>
                    <v-col align="left">
                      {{ scheduleName }}
                    </v-col>
                  </v-row>
                  <!-- <div v-for="(header, i) in Object.keys(editScheduleLog)" :key="i"> -->
                    <!-- <v-row v-if="header!='scheduleName'"> -->
                    <v-row v-for="(logItem, i) in filterLogList(scheduleName)" :key="i">
                      <v-col cols="4" align="right">
                        {{ logItem.header }}
                      </v-col>
                      <v-col cols="4" align="center">
                        {{ getActionValue(logItem, 'master') }}
                      </v-col>
                      <v-col cols="4" align="center">
                        {{ getActionValue(logItem, 'local') }}
                      </v-col>
                    </v-row>
                  <!-- </div> -->
                </div>
                <!-- <v-divider></v-divider>
                <v-row>
                  <v-col cols="4" align="left">
                    追加スケジュール
                  </v-col>
                </v-row>
                <v-divider></v-divider> -->
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" large @click="saveSchedule()">保存</v-btn>
                <v-btn color="primary" large @click="saveDialog=false">キャンセル</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-card>
</template>

<style scoped>
  .container{
     max-width: 98%;
     padding: -1px;
  }
  .cellStyle{
    border: 1px grey solid;
    cursor: pointer;
  }
</style>

<script>
import { mapState, mapActions} from 'vuex'
export default {
  name: 'EditScheduleDataCard',
  data () {
    return {
      perPageNum: 6,
      cellDialog: false,
      schDialog: false,
      noteDialog: false,
      saveDialog: false,
      scheduleItems: [],
      selectedIndex: 0,
      presetTemp: 25,
      shiftTemp: '',
      edittingScheduleName: '',
      edittingNote: '',
      edittingAction: {},
      editLog: {},
      selectedHeader: '',
      actionList: [
        { value:{ operation:'', temperature: '' }, text:'' },
        { value:{ operation:'運転', temperature:'' }, text:'運転' }, 
        { value:{ operation:'停止', temperature:'' }, text:'停止' },
        { value:'設定温度', text:'設定温度' },
        { value:'温度シフト', text:'温度シフト' },
      ],
      selectedSchedule: '',
      shiftTempList: ["+1", "+2", "+3", "-1", "-2", "-3"]
    }
  },
  computed: {
    ...mapState([
      "buildingScheduleHeaders", 
      "buildingScheduleItems", "timeHeaders", "editScheduleLogList"
    ]),
    scheduleHeaders() {
      const scheduleHeaders = [...this.buildingScheduleHeaders]
      scheduleHeaders.splice(1, 0, 
        { "text": "特記事項",
          "value": "note", 
          "align": "center", 
          "sortable": false
        }
      )
      return scheduleHeaders
    },
    scheduleList () {
      const scheduleList = []
      this.scheduleItems.forEach(elm => {
        scheduleList.push(elm["scheduleName"])
      })
      return scheduleList
    },
    editedScheduleList() {
      const scheduleSet = new Set(this.editScheduleLogList.map(elm => elm["scheduleName"])) 
      return Array.from(scheduleSet)
    }
  },
  methods: {
    ...mapActions([
      "saveEditedScheduleData", "compareEditedScheduleData"
    ]),
    getColor (value, time) {
      if(value[time]){
        if(value[time]["operation"] == "停止"){
          return '#FE66FF'
        }
        else if(value[time]["operation"] == "運転"){
          return '#68FE32'
        }
        else if (value[time]["temperature"])
          return '#FFFE08'
      }
    },
    displayTempValue (item, time) {
      const tempValue = item[time]["temperature"]
      if (1 <= tempValue && tempValue <= 3){
        // return '+' + tempValue
        return tempValue
      }
      else{
        return tempValue
      }
    },
    compareSchedule() {
      this.compareEditedScheduleData({ scheduleJson: this.scheduleItems})
      this.saveDialog = true
    },
    saveSchedule() {
      this.saveEditedScheduleData({ scheduleJson: this.scheduleItems})
      this.saveDialog = false
      // this.compareEditedScheduleData({ scheduleJson: this.scheduleItems})
    },
    showCellEditModal: function(header, index) {
      this.selectedIndex = index
      this.selectedHeader = header
      this.presetTemp = 25
      console.log(this.scheduleItems[this.selectedIndex][header])
      const valueObj = this.scheduleItems[this.selectedIndex][header]
      this.edittingAction = ""
      if(valueObj["operation"] == '設定温度'){
        this.edittingAction = '設定温度'
        this.presetTemp = valueObj['temperature']
      }
      else if(valueObj["operation"] == '温度シフト'){
        this.edittingAction = '温度シフト'
        this.shiftTemp = valueObj['temperature']
      }
      else {
        this.edittingAction = valueObj
      }
      this.cellDialog = true
    },
    showSchNameEditModal: function(header, index) {
      this.selectedIndex = index
      this.selectedHeader = header
      this.edittingScheduleName = this.scheduleItems[this.selectedIndex]['scheduleName']
      this.schDialog = true
    },
    showNoteEditModal: function(header, index) {
      this.selectedIndex = index
      this.selectedHeader = header
      this.edittingNote = this.scheduleItems[this.selectedIndex].note
      this.noteDialog = true
    },
    editScheduleName: function() {
      this.scheduleItems[this.selectedIndex]['scheduleName'] = this.edittingScheduleName
      this.schDialog = false
    },
    editNote: function() {
      this.scheduleItems[this.selectedIndex].note = this.edittingNote
      this.noteDialog = false
    },
    editCellSchedule() {
      let action = { operation:'', temperature: '' }
      if (this.edittingAction == '設定温度'){
        action.operation = '設定温度'
        action.temperature = this.presetTemp
      }
      else if (this.edittingAction == '温度シフト'){
        action.operation = '温度シフト'
        action.temperature = this.shiftTemp
      }
      else {
        action = this.edittingAction
      }
      this.scheduleItems[this.selectedIndex][this.selectedHeader] = action
      this.cellDialog = false
    },
    makeSchedule() {
      const newScheduleItem = {}
      this.timeHeaders.forEach(time => {
        newScheduleItem[time] = { operation: '', temperature: '' }
      })
      newScheduleItem.scheduleName = ''
      newScheduleItem.note = ''
      this.scheduleItems.push(newScheduleItem)
    },
    deleteSchedule() {
      // this.scheduleItems.pop()
      let schItems = []
      console.log("delete")
      schItems = this.scheduleItems.filter(elm => 
        elm.scheduleName !== this.selectedSchedule
      )
      this.scheduleItems = schItems
    },
    filterLogList(scheduleName) {
      return this.editScheduleLogList.filter(elm => elm["scheduleName"] == scheduleName)
    },
    getActionValue(editScheduleLog, version) {
      if (editScheduleLog.header == '特記事項'){
        return editScheduleLog[version]
      }
      const values = Object.values(editScheduleLog[version])
      console.log(values)
      
      return values.filter(elm => elm != '' && elm != '温度シフト' && elm != '設定温度')[0]
    }
  },
  mounted: function () {
    console.log(this.buildingScheduleItems)
    this.scheduleItems = [...this.buildingScheduleItems]
  }

}
</script>