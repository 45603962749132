<template>

  <v-card>
    <v-app-bar color="primary" dark>
      <v-toolbar-title><v-icon>mdi-file-upload-outline</v-icon>excelファイルのアップロード</v-toolbar-title>
    </v-app-bar>
      <v-container>
        <v-row justify="center">
          <v-col>
            <h5>ファイルのアップロード</h5>
            <v-row>
              <v-col md="6">
                <v-file-input label="File input" outlined dense v-model="uploadFile"></v-file-input>
              </v-col>
              <v-col md="2">
                <v-btn color="primary" @click="submitFiles()">アップロード</v-btn>
              </v-col>
            </v-row>

            <v-btn text color="deep-purple accent-4" v-on:click="makeUploadMasterData">マスターデータの作成
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
  </v-card>
</template>

<style lang="scss" scoped>
  .container{
     max-width: 98%;
     padding:-1px;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'uploadCsvCard',
  data () {
    return {
      uploadFile: null
    }
  },
  computed: {
    ...mapState([
      'uploadedCsvList'
    ])
  },
  methods: {
    ...mapActions([
      'uploadExcel', 'makeMasterData'
    ]),
    submitFiles() {
        this.$store.dispatch('uploadExcel', { uploadExcel: this.uploadFile })
    },
    async makeUploadMasterData() {
      // データを取得
      await this.makeMasterData()
      
      this.$router.push('/buildingInfoPage')
    //   this.fetchItmScheduleData()
    }
  }
}
</script>