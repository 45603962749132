<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <edit-point-data-card/>
        <br>
        <edit-season-data-card/>
        <br>
        <edit-schedule-data-card/>
      </v-col>
    </v-row>
  </v-container> 
</template>


<script>
import editPointDataCard from '@/components/organisms/EditPointDataCard.vue'
import editSeasonDataCard from '@/components/organisms/EditSeasonDataCard.vue'
import editScheduleDataCard from '@/components/organisms/EditScheduleDataCard.vue'
export default {
  name: 'dataEditPage',
  data () {
      return {
      }
  },
  components: {
    editPointDataCard,
    editSeasonDataCard,
    editScheduleDataCard
  },
  computed: {

  },
  methods: {
  }
}
</script>