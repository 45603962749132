import axios from 'axios'

// const hostDomain = 'http://127.0.0.1:5000'
// const hostDomain = 'https://itm-schedule.smart-e-ops.com'
const hostDomain = 'https://itm-schedule-dev.smart-e-ops.com'

// ITMのページに入ったときに実行される
async function fetchBuildingTable ({ commit }) {
  const response = await axios.post(hostDomain + '/building_selection')
  console.log(response.config.headers.Authorization)
  commit('setBuildingDataList', response.data)
}

async function uploadExcel({ state }, { uploadExcel }){ 
  console.log(state.buildingItem.building_ID)
  let reader = new FileReader();
  reader.readAsDataURL(uploadExcel);
  reader.onload = async function () {

    const resultFile = reader.result.replace(/^data:(.*;base64,)?/, '');
    const response = await axios.post(hostDomain + '/upload_excel', {
      building_id: state.buildingItem.building_ID,
      excel: resultFile
    })
    console.log(response)
  }
  // commit('setUploadCsv', { uploadCsv: fileName })
}

function makeMasterData({ state }){
  return axios.post(hostDomain + '/make_master_data', {
    building_id: state.buildingItem.building_ID,
  })
}

async function uploadCsv({ state, commit }, { uploadCsv, fileName}){ 

  let reader = new FileReader();
  reader.readAsDataURL(uploadCsv);
  reader.onload = async function () {

    const resultFile = reader.result.replace(/^data:(.*;base64,)?/, '');
    const response = await axios.post(hostDomain + '/upload_csv', JSON.stringify({
      file_name: fileName,
      building_id: state.buildingItem.building_ID,
      csv: resultFile
    }))
    console.log(response)
  }
  commit('setUploadCsv', { uploadCsv: fileName })
}

async function fetchSelfComparedSchedule({ state, commit }){
  const response = await axios.post('http://127.0.0.1:5000' + '/fetch_self_compared_schedule', {
    building_id: state.buildingItem.building_ID,
  })
  console.log(response.data)
  commit('setSelfComparedScheduleItems', response.data)
}

async function compareCsvSchedule({ state, commit }){
  const response = await axios.post('http://127.0.0.1:5000' + '/compare_csv_schedule_with_master', {
    building_id: state.buildingItem.building_ID,
  })
  console.log(response.data)
  commit('setComparedCsvScheduleItems', response.data)
}

async function fetchUploadedFileList({ state, commit }){ 
  const response = await axios.post(hostDomain + '/fetch_uploaded_file_list', JSON.stringify({
    building_id: state.buildingItem.building_ID,
  }))
  commit('setUploadedCsvFromS3', response.data)
}


function uploadItmScheduleData({ state }){
  console.log("upload start")
  return axios.post(hostDomain + '/upload_itm_parsed_data', JSON.stringify({
    building_id: state.buildingItem.building_ID,
  }))
}

// 物件情報ページ
async function fetchBuildingPointItems ( { state, commit } ){
  const response = await axios.post(hostDomain + '/fetch_building_point_items', {
    building_id: state.buildingItem.building_ID,
  })
  console.log(response.data)
  commit('setBuildingPointItems', response.data)
} 

async function fetchBuildingScheduleTableData ({ state, commit }){
  const response = await axios.post(hostDomain + '/fetch_building_schedule_items', {
    building_id: state.buildingItem.building_ID
  })
  console.log(response.data)
  commit('setBuildingScheduleHeaders', response.data.header)
  commit('setBuildingScheduleItems', response.data.items)
  commit('setTimeHeaders', response.data.time_headers)
}
// スケジュール可視化ページ
async function fetchPointScheduleTableData ( { state, commit }) {
  const params = {
    building_id: state.buildingItem.building_ID,
  }
  const response = await axios.post(hostDomain + '/fetch_fixed_period_schedule_items', params)
  console.log(response.data)
  commit('setScheduleSeasonList', response.data.seasons)
  commit('setSeason', response.data.seasons[0])
  commit('setTableHeader', response.data.headers)
  commit('setfixedPeriodScheduleData', response.data.items)
  commit('setTimeHeaders', response.data.time_headers)
}

async function fetchDateScheduleTableData ( { state, commit }, { date } ) {
  const response = await axios.post(hostDomain + '/fetch_date_schedule_items', {
    date: date,
    building_id: state.buildingItem.building_ID
  })
  console.log(response.data)
  commit('setDateScheduleData', response.data)
}

async function compareEditedScheduleData ({ state, commit }, { scheduleJson }){

  console.log(state.buildingItem.building_ID)
  const response = await axios.post(hostDomain + '/compare_edited_schedule', {
    building_id: state.buildingItem.building_ID,
    schedule_json: scheduleJson
  })
  console.log(response.data)
  commit('setEditScheduleLogList', response.data)
}

async function compareEditedPointData( { state, commit }, { pointJson }){
  console.log(state.buildingItem.building_ID)
  const response = await axios.post(hostDomain + '/compare_edited_point', {
    building_id: state.buildingItem.building_ID,
    point_json: pointJson
  })
  console.log(response.data)
  commit('setEditPointLogList', response.data)
}

async function compareEditedSeasonData( { state, commit }, { seasonJson }){
  console.log(state.buildingItem.building_ID)
  const response = await axios.post(hostDomain + '/compare_edited_season', {
    building_id: state.buildingItem.building_ID,
    season_json: seasonJson
  })
  console.log(response.data)
  commit('setEditSeasonLog', response.data)
}

async function saveEditedScheduleData ({ state }, { scheduleJson }){
  console.log(state.buildingItem.building_ID)
  const response = await axios.post(hostDomain + '/save_edited_schedule', {
    building_id: state.buildingItem.building_ID,
    schedule_json: scheduleJson
  })
  console.log(response)
}

async function saveEditedPointData ({ state }, { editLog }){
  console.log(state.buildingItem.building_ID)
  const response = await axios.post(hostDomain + '/save_edited_point', {
    building_id: state.buildingItem.building_ID,
    edit_log: editLog
  })
  console.log(response)
}

async function saveEditedSeasonData ({ state }, { editLog }){
  console.log(state.buildingItem.building_ID)
  const response = await axios.post(hostDomain + '/save_edited_season', {
    building_id: state.buildingItem.building_ID,
    edit_log: editLog
  })
  console.log(response)
}

async function fetchItmScheduleData ({ state, commit }) {
  console.log("fetchItmScheduleData")
  const response = await axios.post(hostDomain + '/fetch_itm_table_data', JSON.stringify({
    building_id: state.buildingItem.building_ID,
  }))
  console.log(response.data)
  commit('setTableHeader', response.data.header)
  commit('setfixedPeriodScheduleData', response.data.item)
  commit('setScheduleSeasonList', response.data.seasons)
  commit('setSeason', response.data.seasons[0].value)

}

async function fetchDurationScheduleData ({ state, commit }, { firstDate, lastDate }) {
  console.log(firstDate, lastDate)
  const response = await axios.post(hostDomain + '/fetch_duration_data', JSON.stringify({
    first_date: firstDate,
    last_date: lastDate,
    building_id: state.buildingItem.building_ID
  }))
  console.log(response.data)
  commit('setAnyPeriodScheduleData', response.data)

}

export default {
  fetchItmScheduleData,
  fetchBuildingTable,
  uploadItmScheduleData,
  fetchBuildingPointItems,
  fetchBuildingScheduleTableData,
  fetchPointScheduleTableData,
  fetchDateScheduleTableData,
  saveEditedScheduleData,
  saveEditedPointData,
  saveEditedSeasonData,
  compareEditedScheduleData,
  compareEditedPointData,
  compareEditedSeasonData,
  fetchDurationScheduleData,
  uploadExcel,
  makeMasterData,
  uploadCsv,
  fetchSelfComparedSchedule,
  compareCsvSchedule,
  fetchUploadedFileList
}