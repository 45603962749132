<template>
  <v-row>
    <v-col md="3">
      <v-text-field v-model="date" outlined label="日付">
        <template v-slot:append>
            <v-menu v-model="dateMenu" offset-y :close-on-click="true" :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-btn icon color="primary" dark elevation="0" v-on="on">
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </template>
            <v-date-picker 
              v-model="date"
              @input="dateMenu = false"
              locale="jp-ja"
              :day-format="date => new Date(date).getDate()"
              @change="date">
            </v-date-picker>
            </v-menu>
        </template>
      </v-text-field>
    </v-col>
    <v-col md="3">
      <v-btn x-large color="primary" @click="$emit('click-fetch-data', {date: date })">データ取得</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      date: null,// new Date().toISOString().substr(0, 10)
      dateMenu: false,
    }
  }
}
</script>