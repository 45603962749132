<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <upload-csv-card/>
        <br>
        <compare-csv-schedule-card/>
        <br>
        <upload-excel-card/>
      </v-col>
    </v-row>
  </v-container> 
</template>


<script>
import uploadCsvCard from '@/components/organisms/UploadCsvCard.vue'
import compareCsvScheduleCard from '@/components/organisms/CompareCsvScheduleCard.vue'
import uploadExcelCard from '@/components/organisms/UploadExcelCard.vue'
export default {
  name: 'uploadPage',
  data () {
      return {

      }
  },
  components: {
    uploadCsvCard,
    compareCsvScheduleCard,
    uploadExcelCard
  },
  computed: {

  },
  methods: {
  }
}
</script>