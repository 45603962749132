<template>

  <v-card>
    <v-app-bar color="primary" dark>
      <v-toolbar-title><v-icon>mdi-timetable</v-icon>ITMスケジュール設定の可視化</v-toolbar-title>
    </v-app-bar>

      <v-container fluid>
        <v-row justify="center">
          <v-col>
            <v-row>
              <v-radio-group row v-model="radios">
                <v-radio
                  label="期間の選択"
                  value="period"
                ></v-radio>
                <v-radio
                  label="日付の指定"
                  value="day"
                ></v-radio>
              </v-radio-group>
            </v-row>

              <v-row v-show="periodType">
                <v-col md="3">
                  <v-select 
                    v-model="selectedSeason"
                    :items="seasonList" 
                    label="期間の選択"
                    item-text="text"
                    item-value="value"
                    outlined 
                    return-object
                  >
                  </v-select>
                </v-col>
              </v-row>
              <date-input 
                v-show="!periodType" 
                @click-fetch-data="fetchDateScheduleData($event)"
              ></date-input>

              <v-data-table
                :headers="scheduleTableHeaders"
                :items="scheduleTableData"
                :items-per-page="10"
                class="elevation-1"
                :fixed-header="true"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td align="center">
                      <v-menu open-on-hover :offset-x="true">
                        <template v-slot:activator="{ on }">
                          <v-chip color="success" dark v-on="on">{{ item['pointName'] }}</v-chip>
                        </template>
                        <v-list>
                          <v-list-item v-for="(scheduleName, index) in item['scheduleNameList']" :key="index">
                            <v-list-item-title>{{ scheduleName }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                    <td align="center">{{ item["detailName"] }}</td>
                    <td align="center">{{ item["coolLower"] }}</td>
                    <td align="center">{{ item["heatUpper"] }}</td>
                    <!-- <td align="center">{{ item["portNo"] }}</td> -->
                    <!-- <td>{{ item["address"] }}</td> -->
                    <td 
                      v-for="(time, i) in timeHeaders" :key="i"
                      :bgcolor="getColor(item, time)"
                      align="center"
                      class="cellStyle"
                      height="3rem"
                    >
                      {{ item[time]["temperature"] }}<!-- {{item[time]["minute"]}} -->
                    </td>
                  </tr>
                </template>
              </v-data-table>

          </v-col>
        </v-row>
      </v-container>
  </v-card>
</template>
<style lang="scss" scoped>
  .v-data-table {
    white-space: nowrap;
  }
  .v-data-table--fixed-header >>> th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 9999;
    background: white;
  }
  .container{
     max-width: 98%;
    //  max-height: 98%;
     padding:-1px;
  }
  .cellStyle{
    border: 1px grey solid;
  }
</style>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import dateInput from '@/components/molecules/DateInput.vue'
export default {
  name: 'ITMScheduleExcelTableCard',
  data () {
    return {
      radios: 'period',
      date: null,
      dateMenu: false
    }
  },
  components: {
    dateInput
  },
  computed: {
    ...mapState([
      'fixedPeriodScheduleData', 'seasonList', 'season',
      'scheduleTableHeaders', 'dateScheduleData', 'timeHeaders'
    ]),
    periodType() {
      if (this.radios == "period"){
        return true
      }
      else{
        return false
      }
    },
    scheduleTableData() {
      // 期間の選択
      if(this.radios == 'period'){
        console.log(this.fixedPeriodScheduleData[this.season])
        return this.fixedPeriodScheduleData[this.season]
      }else{
        return this.dateScheduleData
      }

    },
    selectedSeason: {
      get() {
        return this.season
      },
      set(season){
        console.log(season)
        this.setSeason(season)
      }
    },
  },
  methods: {
    ...mapMutations([
      'setSeason'
    ]),
    ...mapActions([
      'fetchPointScheduleTableData', 'fetchDateScheduleTableData'
    ]),
    getColor (value, time) {
      if(value[time]){
        if(value[time]["operation"] == "停止"){
          return '#FE66FF'
        }
        else if(value[time]["operation"] == "運転"){
          return '#68FE32'
        }
        else if (value[time]["temperature"])
          return '#FFFE08'
      }
    },
    fetchDateScheduleData(value){
      // value = { date: this.date}
      console.log(value)
      this.fetchDateScheduleTableData(value)
    }
  },
  created: function () {
  // データを取得する
    this.fetchPointScheduleTableData() // action
  }
}
</script>