<template>

  <v-card>
    <v-app-bar color="primary" dark>
      <v-toolbar-title><v-icon>mdi-file-upload-outline</v-icon>csvファイルのアップロード</v-toolbar-title>
    </v-app-bar>
      <v-container>
        <v-row justify="center">
          <v-col>
            <h5>ファイルのアップロード</h5>
            <v-row>

              <v-col md="4">
                <v-select 
                v-model="selectedFile"
                :items="toUploadCsvList" 
                label="ファイルの選択"
                item-text="text"
                item-value="value"
                outlined 
                return-object>
                </v-select>
              </v-col>
              <v-col md="6">
                <v-file-input label="File input" outlined dense v-model="uploadFile"></v-file-input>
              </v-col>
              <v-col md="2">
                <v-btn color="primary" @click="submitFiles()">アップロード</v-btn>
              </v-col>
            </v-row>

            <h5>アップロード済みファイル</h5>
            <li v-for="uploadedCsv in uploadedCsvList" :key="uploadedCsv">{{ uploadedCsv }}</li>
            <!-- <v-btn text color="deep-purple accent-4" @click="makeItmScheduleData">スケジュールデータの作成</v-btn> -->
            <v-btn color="primary" @click="compareSchedule()">照合</v-btn>
            <br>
          </v-col>
        </v-row>
      </v-container>
  </v-card>
</template>

<style lang="scss" scoped>
  .container{
     max-width: 98%;
     padding:-1px;
  }
</style>

<script>

import { mapState, mapActions } from 'vuex'
export default {
  name: 'uploadCsvCard',
  data () {
    return {
      toUploadCsvList: [
        { text: "ManagementPointData.csv", value: "ManagementPointData.csv" },
        { text: "AreaData.csv", value: "AreaData.csv" },
        { text: "Schedule.csv", value: "Schedule.csv" }
      ],
      selectedFile: null,
      uploadFile: null
    }
  },
  components: {
  },
  computed: {
    ...mapState([
      'uploadedCsvList'
    ])
  },
  methods: {
    ...mapActions([
      'fetchUploadedFileList', 'uploadCsv', 'uploadItmScheduleData', 
      'fetchItmScheduleData', 'fetchSelfComparedSchedule', 'compareCsvSchedule'
    ]),
    submitFiles() {
        this.$store.dispatch('uploadCsv', { uploadCsv: this.uploadFile, fileName: this.selectedFile.value })
    },
    async makeItmScheduleData() {
      // データを取得
      await this.uploadItmScheduleData()
      
      this.$router.push('/itmScheduleTablePage')
      this.fetchItmScheduleData()
    },
    async compareSchedule() {
      await this.fetchSelfComparedSchedule()
      this.compareCsvSchedule()
    }
  },
  created: function () {
    console.log("アップロード済みファイルリスト", this.uploadedCsvList)
    // まだアップロード済みのファイルを取得していないなら
    if (this.uploadedCsvList.length == 0){
      this.fetchUploadedFileList()
    }
  }
}
</script>