<template>
  <v-container fluid>
    <v-card>
      <v-app-bar color="primary" dark>
        <v-toolbar-title><v-icon>mdi-information</v-icon>スケジュール情報</v-toolbar-title>
      </v-app-bar>
      <v-container fluid>
        <v-row justify="center">
          <v-col>
            <v-data-table
              :headers="buildingScheduleHeaders"
              :items="buildingScheduleItems"
              :items-per-page="perPageNum"
              @update:items-per-page="setPerPageNum(num)"
              class="elevation-1"
              fixed-header
            >
            <template v-slot:item="{ item }">
              <tr>
                <td align="center">
                  <!-- <v-chip color="success" dark>{{ item['scheduleName'] }}</v-chip> -->
                  <v-menu open-on-hover :offset-x="true" >
                    <template v-slot:activator="{ on }">
                      <v-chip color="success" dark v-on="on">{{ item['scheduleName'] }}</v-chip>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title>{{ item.note }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td 
                  v-for="(time, index) in timeHeaders" :key="index" 
                  :bgcolor="getColor(item, time)"
                  align="center"
                  style="border:1px grey solid"
                  height="3rem"
                >
                  {{ item[time]["temperature"] }}
                  <!-- {{ displayTempValue(item, time) }} -->
                </td>
              </tr>
            </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<style scoped>
  .v-data-table {
    white-space : nowrap;
  }
  .container{
     max-width: 98%;
     padding:-1px;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'BuildingInfoCard',
  data () {
    return {
      perPageNum: 6
    }
  },
  computed: {
    ...mapState([
      "buildingScheduleHeaders", 
      "buildingScheduleItems", "timeHeaders"
    ])
  },
  methods: {
    ...mapActions([
      'fetchBuildingScheduleTableData'
    ]),
    getColor (value, time) {
      if(value[time]){
        if(value[time]["operation"] == "停止"){
          return '#FE66FF'
        }
        else if(value[time]["operation"] == "運転"){
          return '#68FE32'
        }
        else if (value[time]["temperature"])
          return '#FFFE08'
      }
    },
    setPerPageNum(num){
      console.log(num)
      this.perPageNum = num
    }
  },
  created: function () {
    this.fetchBuildingScheduleTableData()
  }
}
</script>
