<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <building-point-info-card/>
        <br>
        <building-schedule-info-card/>
      </v-col>
    </v-row>
  </v-container> 
</template>


<script>
import buildingPointInfoCard from '@/components/organisms/BuildingPointInfoCard.vue'
import buildingScheduleInfoCard from '@/components/organisms/BuildingScheduleInfoCard.vue'
export default {
  name: 'selectBuildingPage',
  data () {
      return {
          
      }
  },
  components: {
    buildingPointInfoCard,
    buildingScheduleInfoCard
  },
  computed: {

  },
  methods: {
  }
}
</script>