<template>
    <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    class="elevation-1"
    >
    <template v-slot:item="{ item }">
        <tr>
          <td align="center">{{ item['schName'] }}</td>
          <td align="center">
            {{ parseCommaStr(item['diffMonday']) }}
          </td>
          <td align="center">
            {{ parseCommaStr(item['time']) }}
          </td>
        </tr>
    </template>
    </v-data-table>
</template>

<script>
// import { mapState, mapActions } from 'vuex'
export default {
  name: 'selfComparedCsvScheduleTable',
  props:{
    items: { type: Array }, //{ text: '詳細名称', value: 'detailName' }
  },
  data () {
    return {
      headers:  [
        { "text": "スケジュール名", "align": "center", "sortable": false, "value": "schName" },
        { "text": "月曜日の設定と異なる曜日", "align": "center", "sortable": false, "value": "diffMonday" },
        { "text": "30分単位でない時刻(月曜日)", "align": "center", "sortable": false, "value": "time" },
      ]
    }
  },
  methods: {
    parseCommaStr(dayList) {
      return dayList.join(',')
    }
  }
}
</script>