<template>
    <v-navigation-drawer  clipped app left> 
      <br>
      <br>
      <br>
      <v-list>
        <v-list-item>
          <v-list-item-title class="headline">
            <small>ユーザ:{{ userName }}</small>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="headline">
            <small>物件名: {{ buildingName }}</small>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
          v-for="page in pages"
          :key="page.title"
          link
          @click="cardChange(page.path)"
        >
          <v-list-item-icon>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

</template>

<style lang="scss" scoped>
    .headline {
        text-overflow: inherit;
        white-space: unset;
    }
</style>

<script>
  import { mapState } from 'vuex'
  export default {
    data () {
      return {
        items: [
          { key: "ユーザ名", text: this.userName },
          { key: "物件名", text: this.buildingName },
        ],
        pages: [
          { title: '物件選択', icon: 'mdi-home', path: "/selectBuilding" },
          { title: 'アップロード', icon: 'mdi-file-upload-outline', path: "/uploadPage" },
          { title: '物件情報', icon: 'mdi-information', path: "/buildingInfoPage" },
          { title: 'スケジュール可視化', icon: 'mdi-timetable', path: "/scheduleTablePage" },
          { title: '設定データ編集', icon: 'mdi-table-edit', path: "/editDataPage" }
        ],
        right: null,
      }
    },
    computed: {
      ...mapState(
        ['userName', 'buildingItem']
      ),
      buildingName(){
        console.log(this.buildingItem.building_name)
        return this.buildingItem.building_name
      }
    },
    methods:{
      cardChange(path){
        this.$router.push(path)
      }
    }
  }
</script>